import { atom, selector } from "recoil";

export const itemsState = atom({
  key: "itemsState",
  default: [],
});
export const itemsSortedState = selector({
  key: "itemsSortedState",
  get: ({ get }) => {
    const clone = [...get(itemsState)];
    return clone.length > 0
      ? clone.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      : clone;
  },
});
