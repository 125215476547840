import React, { useState, useEffect } from "react";
import fetch from "node-fetch";
import { Card } from "../components/Card";
import { MenuPicker } from "../components/MenuPicker";
import {
  IonFabButton,
  IonFab,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
  IonList,
  IonChip,
  IonLabel,
  IonIcon,
  IonLoading,
  IonModal,
  IonButton,
  IonToast,
  IonAlert,
} from "@ionic/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { itemsSortedState, itemsState } from "../states/items";
import { Total } from "../components/Total";
import { Owner } from "../components/Owner";
import {
  arrowBack,
  cartOutline,
  listOutline,
  fastFoodOutline,
  logoWhatsapp,
} from "ionicons/icons";
import { COLLECTIONS, COLLECTION, WA_ORDER, CONTACTS, RUENTA_URL } from "../utils/api";
import "./Store.css";

const Store = ({ match, history }) => {
  const { store, address, pointName, token, customerName } = match.params;
  const url = match.url;
  const [showLoading, setShowLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [collection, setCollection] = useState(null);
  const [section, setSection] = useState(null);
  const [collections, setCollections] = useState([]);
  const [sections, setSections] = useState([]);
  const [products, setProducts] = useState([]);
  const [collectionsOpen, setCollectionsOpen] = useState(false);
  const [sectionsOpen, setSectionsOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [storePhone, setStorePhone] = useState("34666860371");
  const items = useRecoilValue(itemsSortedState);
  const setMyItems = useSetRecoilState(itemsState);

  const prepareOrder = async (store, address) => {
    setShowLoading(true);
    const response = await fetch(`${CONTACTS}/${store}/${address}`, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    });
    const dataStore = await response.json();
    const varStorePhone =
      dataStore.contacts.length > 0
        ? dataStore.contacts[0].phone
        : "34666860371";
    setStorePhone(varStorePhone);
    console.log({ storePhone });
    setShowLoading(false);
    setShowAlert(true);
  };

  const placeOrder = async (customerData, items, store, address) => {
    setShowLoading(true);
    const myOrder = items
      .map((item) => `[${item.qty}] ${item.name}`)
      .join(", \n");
    const data = {
      customerToken: localStorage.getItem("token"),
      ...customerData,
      order: myOrder,
      store: store,
      address: address,
      callback: `${RUENTA_URL}${url}`,
    };
    console.log(data);
    await fetch(`${WA_ORDER}`, {
      method: "post",
      mode: "no-cors",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    //window.location.href=`${WA_ME_URL}/${whatsapp.startsWith("+") ? whatsapp : whatsapp || '+3466860371'}?text=${myOrder}`;
    setMyItems([]);
    setShowLoading(false);
    setShowCheckout(false);
    setShowToast(true);
  };

  const getCollections = async (store, address) => {
    const collectionsResp = await fetch(
      `${COLLECTIONS}?storeId=${store}&addressId=${address}`
    );
    const response = await collectionsResp.json();
    return response.collections;
  };
  const getSections = async (store, address, collectionId) => {
    const sectionsResp = await fetch(
      `${COLLECTION}?storeId=${store}&addressId=${address}&collectionId=${collectionId}`
    );
    const response = await sectionsResp.json();
    return response.sections;
  };

  const handleSections = async () => {
    if (collection) {
      const { store, address } = match.params;
      setShowLoading(true);
      const mySections = await getSections(store, address, collection.value);
      setShowLoading(false);
      setSection({
        value: mySections && mySections[0] ? mySections[0].id : "",
        text: mySections && mySections[0] ? mySections[0].name : "",
      });
      setSections(mySections);
      setProducts(mySections && mySections[0] ? mySections[0].products : []);
      token && setShowToast(true);
    }
  };

  useIonViewWillEnter(async () => {
    setShowLoading(true);
    const { store, address } = match.params;
    const myCollections = await getCollections(store, address);
    setCollections(myCollections);
    setCollection({
      value: myCollections[0] ? myCollections[0].id : "",
      text: myCollections[0] ? myCollections[0].title : "",
    });
    const mySections = await getSections(
      store,
      address,
      myCollections[0] ? myCollections[0].id : ""
    );
    setShowLoading(false);
    setSections(mySections);
    setSection({
      value: mySections && mySections[0] ? mySections && mySections[0].id : "",
      text: mySections && mySections[0] ? mySections[0].name : "",
    });
    setProducts(mySections && mySections[0] ? mySections[0].products : []);
  });

  useEffect(() => {
    handleSections();
    // eslint-disable-next-line
  }, [collection]);

  useEffect(() => {
    token && localStorage.setItem("token", token);
  }, [token]);

  useEffect(() => {
    customerName && localStorage.setItem("customerName", customerName);
  }, [customerName]);

  const { mapSettings } = match.params;
  const isAdmin = match.url.toString().indexOf("/storeAdmin") > -1;
  return (
    <IonPage id="view-message-page">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Order placed! Thank you!"
        duration={2000}
      />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Confirmation"}
        message={
          !localStorage.getItem("token")
            ? "Hey! We just need to validate your request through whatsapp, only 15 seconds! May we proceed?"
            : "We will send your request!"
        }
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Ok",
            cssClass: "primary",
            handler: (customerData) => {
              placeOrder(customerData, items, store, address);
            },
          },
        ]}
        inputs={
          !localStorage.getItem("token")
            ? [
                {
                  name: "name",
                  type: "text",
                  placeholder: "Your Name",
                },
                {
                  name: "phone",
                  type: "text",
                  placeholder: "Your Phone with country prefix",
                },
              ]
            : []
        }
      />
      <IonModal isOpen={showCheckout} cssClass="my-custom-class">
        <IonItem>
          <IonButton type="submit" onClick={() => setShowCheckout(false)}>
            <IonIcon icon={arrowBack} />
          </IonButton>
        </IonItem>
        <IonContent>
          <Total items={items} pointName={pointName} />
          <Owner />
          <IonFab
            vertical="top"
            horizontal="end"
            slot="fixed"
            onClick={() => prepareOrder(store, address)}
          >
            {items.length > 0 && (
              <IonFabButton>
                <IonIcon icon={logoWhatsapp} />
              </IonFabButton>
            )}
          </IonFab>
          <IonList>
            {items.map((item, index) => (
              <IonItem key={index}>
                <Card isCheckout item={item} />
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons>
            <IonBackButton
              text=""
              defaultHref={`/${isAdmin ? "admin" : "map"}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/${isAdmin ? "admin" : "map"}/${mapSettings}`);
              }}
            ></IonBackButton>
            {collections && collections.length > 1 && (
              <IonChip
                outline
                color="tertiary"
                onClick={() => {
                  setCollectionsOpen(true);
                }}
              >
                <IonIcon icon={listOutline}></IonIcon>
                {collection && <IonLabel>{collection.text}</IonLabel>}
              </IonChip>
            )}

            {sections && sections.length > 1 && (
              <IonChip
                outline
                color="tertiary"
                onClick={() => {
                  setSectionsOpen(true);
                }}
              >
                <IonIcon icon={fastFoodOutline}></IonIcon>
                {section && <IonLabel>{section.text}</IonLabel>}
              </IonChip>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Total items={items} pointName={pointName} />
        <Owner />
        {items.length > 0 && (
          <IonFab
            vertical="top"
            horizontal="end"
            slot="fixed"
            onClick={() => setShowCheckout(true)}
          >
            <IonFabButton>
              <IonIcon icon={cartOutline} />
            </IonFabButton>
          </IonFab>
        )}
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Just a moment please..."}
          duration={0}
        />
        <MenuPicker
          isOpen={collectionsOpen}
          options={collections}
          name="Collections"
          onCancel={() => {
            setCollectionsOpen(false);
          }}
          onSave={(obj) => {
            setCollection(obj);
            setCollectionsOpen(false);
          }}
        />
        <MenuPicker
          isOpen={sectionsOpen}
          options={sections}
          name="Sections"
          onCancel={() => {
            setSectionsOpen(false);
          }}
          onSave={(obj) => {
            setSection(obj);
            setProducts(
              sections[
                sections.findIndex((item) => {
                  return item.id === obj.value;
                })
              ].products
            );
            setSectionsOpen(false);
          }}
        />

        <IonList>
          {products.map((item, index) => (
            <IonItem key={index}>
              <Card item={item} />
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Store;
