import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonImg,
  IonRow,
  IonCol,
  IonGrid,
  IonButton,
  IonCardContent,
  IonBadge,
} from "@ionic/react";
import { useRecoilState } from "recoil";
import "./Card.css";
import { itemsState } from "../states/items";

export const Card = ({ item, isCheckout = false }) => {
  const [items, setItems] = useRecoilState(itemsState);
  const product = items.filter((register) => register.id === item.id)[0];
  const addItem = (itemState) => {
    setItems((oldItems) => {
      const myItem2Add =
        oldItems.filter((o) => o.id === itemState.id)[0] || null;
      const clone = myItem2Add
        ? [
            ...oldItems.filter((item) => item.id !== itemState.id),
            { ...myItem2Add, qty: myItem2Add.qty + 1 },
          ]
        : [...oldItems, { ...itemState, qty: 1 }];
      return clone;
    });
  };
  const removeItem = (itemState) => {
    setItems((oldItems) => {
      const myItem2Remove =
        oldItems.filter((o) => o.id === item.id && o.qty > 1)[0] || null;
      const clone = myItem2Remove
        ? [
            ...oldItems.filter((item) => item.id !== itemState.id),
            { ...myItem2Remove, qty: myItem2Remove.qty - 1 },
          ]
        : oldItems.filter((item) => item.id !== itemState.id);
      return clone;
    });
  };

  return (
    <IonCard className="item">
      {item.imageId && !isCheckout && (
        <IonImg
          src={`https://res.cloudinary.com/glovoapp/w_680,h_240,c_fit,f_auto,q_auto/${item.imageId}`}
        />
      )}
      <IonCardHeader>
        <IonCardTitle>
          {`${item.name} `}
          <IonBadge color="success">{`${item.price}€`}</IonBadge>
        </IonCardTitle>
        {!isCheckout && <IonCardSubtitle>{item.description}</IonCardSubtitle>}
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton onClick={() => removeItem(item)}>-</IonButton>
              </IonCol>
              <IonCol>
                <IonButton>{product ? product.qty : 0}</IonButton>
              </IonCol>
              <IonCol>
                <IonButton onClick={() => addItem(item)}>+</IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {product
                  ? `Subtotal: ${(product.qty * product.price).toFixed(2)} €`
                  : ""}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCardHeader>
    </IonCard>
  );
};
