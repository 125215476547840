import React from "react";
import { IonPicker } from "@ionic/react";

export const MenuPicker = ({
  options = [],
  name = "Menu",
  onSave,
  isOpen,
}) => {
  const DayColumn = {
    name: { name },
    options: options.map((item) => {
      return { text: item.title || item.name, value: item.id };
    }),
  };

  return (
    <div>
      <IonPicker
        isOpen={isOpen}
        columns={[DayColumn]}
        buttons={[
          {
            text: "OK",
            handler: (value) => {
              onSave(value["[object Object]"]);
            },
          },
        ]}
      ></IonPicker>
    </div>
  );
};
