export const MAPBOX_API_KEY =
  "pk.eyJ1IjoiYWxleHBhdmxvdiIsImEiOiJjanRidzZ0OW8wb213M3lwY3czaXE0czB1In0.sMhkuE4P-Na7NcmRww_VRQ";
export const MAPBOX_USERID = "alexpavlov";
export const MAPBOX_STYLEID = "ck15hhwry16d01cohyurl8ei4";
export const STORES_SEARCH =
  "https://adoring-liskov-9efb9d.netlify.app/.netlify/functions/stores";
export const WA_ME_URL = "https://wa.me";
export const COLLECTIONS =
  "https://adoring-liskov-9efb9d.netlify.app/.netlify/functions/collections";
export const COLLECTION =
  "https://adoring-liskov-9efb9d.netlify.app/.netlify/functions/collection";
export const CONTACTS =
  "https://adoring-liskov-9efb9d.netlify.app/.netlify/functions/server/points";
export const WA_ORDER =
  "https://adoring-liskov-9efb9d.netlify.app/.netlify/functions/wa_token";
//export const WA_ORDER = "http://localhost:8888/.netlify/functions/wa_token";
export const RUENTA_URL = "https://ruenta.com";
