import React from "react";
import { IonBadge, IonFab } from "@ionic/react";
import "./Card.css";

export const Owner = ({ name }) => {
  return (
    <IonFab vertical="top" horizontal="center" slot="fixed">
      <IonBadge>{name}</IonBadge>
    </IonFab>
  );
};
