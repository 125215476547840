import React, {Suspense, lazy} from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet,IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Store from './pages/Store';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
const Contacts = lazy(() => import("./pages/Contacts"));
const Book = lazy(() => import("./pages/Book"));
const Map = lazy(() => import("./pages/Map"));

const App = () => (
  <IonApp>
    <Suspense fallback={<IonLoading
        cssClass="my-custom-class"
        isOpen={true}
        message={"Just a moment please..."}
        duration={0}
      />}>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/admin/:mapSettings?" component={Map} exact={false} />
        <Route path="/map/:mapSettings?" component={Map} exact={false} />
        <Route path="/store/:store/:address/:mapSettings?/:pointName?/:token?/:customerName?" component={Store} exact={true} />
        <Route path="/storeAdmin/:store/:address/:mapSettings?/:pointName?/:token?/:customerName?" component={Store} exact={true} />
        <Route path="/contacts/:store/:address/:mapSettings?/:pointName?" component={Contacts} exact={true} />
        <Route path="/book/:store/:address/:mapSettings?/:pointName?/:token?/:customerName?" component={Book} exact={true} />
        <Route path="/bookAdmin/:store/:address/:mapSettings?/:pointName?/:token?/:customerName?" component={Book} exact={true} />
        <Route exact path="/" render={() => <Redirect to="/map" />} />
      </IonRouterOutlet>
    </IonReactRouter>
    </Suspense>
  </IonApp>
);

export default App;
