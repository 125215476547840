import React from "react";
import { IonBadge, IonFab } from "@ionic/react";
import "./Card.css";

export const Total = ({ items,pointName }) => {
  return (
    <IonFab vertical="top" horizontal="start" slot="fixed">
        <IonBadge>
        {`${pointName}`}
        </IonBadge>
      <IonBadge>
        {`Total: ${
          items.length > 0
            ? items
                .map((item) => (item.qty || 0) * (item.price || 0 ))
                .reduce((a, b) => a + b, 0)
                .toFixed(2)
            : 0
        } €`}
      </IonBadge>
    </IonFab>
  );
};
